/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import r from "../config.js";
import { id as t } from "../kernel.js";
import { clone as o } from "../core/lang.js";
import { urlToObject as n } from "../core/urlUtils.js";
import { supportsApiKey as e } from "../support/apiKeyUtils.js";
function i(r, t) {
  return t ? {
    ...t,
    query: {
      ...(r ?? {}),
      ...t.query
    }
  } : {
    query: r
  };
}
function f(r) {
  return "string" == typeof r ? n(r) : o(r);
}
function s(r, t, o) {
  const n = {};
  for (const e in r) {
    if ("declaredClass" === e) continue;
    const i = r[e];
    if (null != i && "function" != typeof i) if (Array.isArray(i)) n[e] = i.map(r => s(r));else if ("object" == typeof i) {
      if (i.toJSON) {
        const r = i.toJSON(o?.[e]);
        n[e] = t ? r : JSON.stringify(r);
      } else n[e] = t ? i : JSON.stringify(i);
    } else n[e] = i;
  }
  return n;
}
function u(o, n) {
  return o ? e(o) && (n || r.apiKey) ? n || r.apiKey : t?.findCredential(o)?.token : null;
}
export { i as asValidOptions, s as encode, u as getToken, f as parseUrl };